export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:150px' },
    { key: 'puskesmas', label: "Puskesmas", _style:'min-width:150px' },
    { key: 'kelurahan', label: "Kelurahan", _style:'min-width:150px' },
    { key: 'indikator_1', label: "Jumlah Keluarga Beresiko Stunting", _style:'min-width:300px;' },
    { key: 'indikator_2', label: "Jumlah Anak Stunting (Pendek & Sangat Pendek)", _style:'min-width:300px;' },
    { key: 'indikator_3', label: "% Prevalensi Stunting", _style:'min-width:300px;' },
    { key: 'indikator_4', label: "Remaja putri yang mengonsumsi Tablet Tambah Darah (TTD)", _style:'min-width:300px;' },
    { key: 'indikator_5', label: "Remaja putri yang menerima layanan pemeriksaan status anemia (hemoglobin)", _style:'min-width:300px;' },
    { key: 'indikator_6', label: "Calon pengantin /calon ibu yang menerima Tablet Tambah Darah (TTD)", _style:'min-width:300px;' },
    { key: 'indikator_7', label: "Calon pasangan usia subur (PUS) yang memperoleh pemeriksaan kesehatan sebagai bagian dari pelayanan nikah", _style:'min-width:300px;' },
    { key: 'indikator_8', label: "Cakupan calon Pasangan Usia Subur (PUS) yang menerima pendampingan kesehatan reproduksi dan edukasi gizi sejak 3 bulan pranikah", _style:'min-width:300px;' },
    { key: 'indikator_9', label: "Pasangan calon pengantin yang mendapatkan bimbingan perkawinan dengan materi pencegahan stunting", _style:'min-width:300px;' },
    { key: 'indikator_10', label: "Pasangan Usia Subur (PUS) dengan status miskin dan penyandang masalah kesejahteraan sosial yang menerima bantuan tunai bersyarat", _style:'min-width:300px;' },
    { key: 'indikator_11', label: "Cakupan Pasangan Usia Subur (PUS) dengan status miskin dan penyandang masalah kesejahteraan sosial yang menerima bantuan pangan nontunai", _style:'min-width:300px;' },
    { key: 'indikator_12', label: "Cakupan Pasangan Usia Subur (PUS) fakir miskin dan orang tidak mampu yang menjadi Penerima Bantuan Iuran (PBI) Jaminan Kesehatan", _style:'min-width:300px;' },
    { key: 'indikator_13', label: "Ibu hamil Kurang Energi Kronik (KEK) yang mendapatkan tambahan asupan gizi", _style:'min-width:300px;' },
    { key: 'indikator_14', label: "Ibu hamil yang mengonsumsi Tablet Tambah Darah (TTD) minimal 90 tablet selama masa kehamilan", _style:'min-width:300px;' },
    { key: 'indikator_15', label: "Persentase Unmeet Need pelayanan keluarga berencana", _style:'min-width:300px;' },
    { key: 'indikator_16', label: "Persentase Kehamilan yang tidak diinginkan", _style:'min-width:300px;' },
    { key: 'indikator_17', label: "Bayi usia kurang dari 6 bulan mendapat air susu ibu (ASI) eksklusif", _style:'min-width:300px;' },
    { key: 'indikator_18', label: "Anak usia 6-23 bulan yang mendapat Makanan Pendamping Air Susu Ibu (MP-ASI)", _style:'min-width:300px;' },
    { key: 'indikator_19', label: "Anak berusia di bawah lima tahun (balita) gizi buruk yang mendapat pelayanan tata laksana gizi buruk", _style:'min-width:300px;' },
    { key: 'indikator_20', label: "Anak berusia di bawah lima tahun (balita)  yang dipantau pertumbuhan dan perkembangannya", _style:'min-width:300px;' },
    { key: 'indikator_21', label: "Anak berusia di bawah lima tahun (balita) gizi kurang yang mendapat tambahan asupan gizi", _style:'min-width:300px;' },
    { key: 'indikator_22', label: "Balita yang memperoleh imunisasi dasar lengkap", _style:'min-width:300px;' },
    { key: 'indikator_23', label: "Keluarga yang Stop BABS", _style:'min-width:300px;' },
    { key: 'indikator_24', label: "Keluarga yang melaksanakan PHBS", _style:'min-width:300px;' },
    { key: 'indikator_25', label: "Keluarga berisiko stunting yang mendapatkan promosi peningkatan konsumsi ikan dalam negeri", _style:'min-width:300px;' },
    { key: 'indikator_26', label: "Pelayanan Keluarga Berencana (KB) pascapersalinan", _style:'min-width:300px;' },
    { key: 'indikator_27', label: "Keluarga berisiko stunting yang memperoleh pendampingan", _style:'min-width:300px;' },
    { key: 'indikator_28', label: "Keluarga berisiko stunting yang mendapatkan manfaat sumber daya  pekarangan untuk peningkatan asupan gizi", _style:'min-width:300px;' },
    { key: 'indikator_29', label: "Rumah tangga yang mendapatkan akses air minum layak", _style:'min-width:300px;' },
    { key: 'indikator_30', label: "Rumah tangga yang mendapatkan akses sanitasi (air limbah domestik) layak ", _style:'min-width:300px;' },
    { key: 'indikator_31', label: "Kelompok Keluarga Penerima Manfaat (KPM) Program Keluarga Harapan (PKH) yang mengikuti Pertemuan Peningkatan Kemampuan Keluarga (P2K2) dengan modul kesehatan dan gizi", _style:'min-width:300px;' },
    { key: 'indikator_32', label: "Keluarga Penerima Manfaat (KPM) dengan ibu hamil, ibu menyusui, dan baduta yang menerima variasi bantuan pangan selain beras dan telur", _style:'min-width:300px;' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:300px;' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:300px;' },
    // { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
